import React from "react";
import { HashLink as Link } from 'react-router-hash-link'; 
import { Row, Col } from "antd";
import "./footer.css";


const Footer = () => {
  return (
    <>
      <section className="footer padding-s">
        <div className="section-container">
          <div className="footer-pill">
            <Link className="logo-wrap" to="/#home" smooth>
              <img
                className="footer-logo"
                src="../../img/navigation/logo.svg"
                alt=""
              />
              <span className="fs-6-fixed clr-white ff-medium">
                Let's Power It
              </span>
            </Link>
            <Link
              className="footer-social"
              to="https://www.linkedin.com/in/maxherchet"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../../img/footer/linkedin.svg" alt="" />
            </Link>
          </div>
          <Row className="footer-nav-row">
            <Col xs={24} md={12} className="footer-nav-col">
              <span className="fs-6-fixed ff-regular clr-white">Links</span>
              <ul className="footer-nav ff-light fs-7-fixed">
                <li>
                  <Link to="/datenschutz">Datenschutz</Link>
                </li>
                <li>
                  <Link to="/impressum">Impressum</Link>
                </li>
              </ul>
            </Col>
            <Col xs={24} md={12} className="footer-copyright-col">
            <span className="fs-7-fixed ff-light">Copyright © {new Date().getFullYear()} by letspower.it</span>
            </Col>
          </Row>
        </div>
      </section>
      {/* <section className="credits">
        <div className="section-container">
          <Row className="credits-row">
            <Col className="align-center" span={24}>
              <Link
                to="https://www.jannickvonroden.com"
                target="_blank"
                rel="noopener noreferrer"
                className="ff-light fs-7-fixed"
              >
                Website by Jannick von Roden
              </Link>
            </Col>
          </Row>
        </div>
      </section> */}
    </>
  );
};

export default Footer;
