import { v4 as uuid4 } from "uuid";

const courseEntries = [
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "3D-Druck und Additive Fertigung",
    courseTitle: "3D-Druck und Additive Fertigung",
    courseSubtitle: "Zerspanung ist nicht alles",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "additive-manufacturing",
    mainTopics: ["Maschinenbau", "Elektrotechnik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "CAD",
        color: "#C1121F",
      },
      {
        id: uuid4(),
        name: "Sensorik",
        color: "#FD8A09",
      },
      {
        id: uuid4(),
        name: "Mikrocontroller",
        color: "#C11CAD",
      },
      {
        id: uuid4(),
        name: "Additive Prozesse",
        color: "#3A0CA3",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Grundprinzipien eines 3D Druckers",
            "Umgang mit FDM Druckern",
            "Druck-Vorbereitung und -Durchführung",
            "Druckmaterialien",
            "Eigenes Modell suchen und drucken",
            "Nachbearbeitung"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs richtet sich an Leute wie dich, die in die Welt der 3D-Drucktechnologie einsteigen möchten. In diesem Kurs lernst du die Grundlagen der additiven Fertigung kennen, einschließlich der verschiedenen Druckverfahren, Materialien und Anwendungen. Du wirst erfahren, wie man 3D-Modelle für den Druck vorbereitet und diese schließlich an den 3D-Drucker übermittelt. Darüber hinaus wirst du lernen, wie man 3D-Drucker bedient und wartet, um qualitativ hochwertige und zuverlässige Ergebnisse zu erzielen. Im Laufe des Kurses wirst du die verschiedenen Materialien für die additive Fertigung kennenlernen, wie Kunststoffe, Metalle und Keramiken, sowie deren jeweiligen Anwendungen und Eigenschaften. Du wirst auch lernen, wie man 3D-Drucke nachbearbeitet und ihnen den letzten Schliff gibt. Am Ende des Kurses wirst du in der Lage sein, deine 3D-Modelle zu drucken, sowie die verschiedenen Materialien und Anwendungen der additiven Fertigung zu verstehen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "FDM Druck Optimierung",
            "Umgang mit SLA Druckern",
            "SLA Druck Optimierung",
            "Druck-Vorbereitung und -Durchführung",
            "Fehlerbilder erkennen",
            "Eigenes Modell in verschiedenen Materialien drucken",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs ist für Personen gedacht, die ihr Wissen in der additiven Fertigung vertiefen wollen und ihre Fähigkeiten erweitern möchten. In diesem Kurs lernst du Techniken zur Optimierung von 3D-Druck Designs kennen. Der Kurs beginnt mit einer detaillierten Betrachtung der Materialien und ihrer Eigenschaften, damit du die optimale Auswahl für verschiedene Anwendungen treffen kannst. Du wirst lernen, wie man die Druckparameter auf der Basis von Material- und Konstruktionsanforderungen optimiert, um die Druckqualität zu verbessern und die Druckzeit zu reduzieren. Im weiteren Verlauf des Kurses wirst du fortgeschrittene Themen wie das Erstellen und Optimieren von Unterstützungsstrukturen, das Entwerfen und Drucken von komplexen Geometrien, das Hinzufügen von Farben und Texturen sowie das Nachbearbeiten von Drucken behandeln. Darüber hinaus wirst du lernen, wie man Fehler bei der Fertigung diagnostiziert und behebt und wie man additive Fertigung in einem industriellen Umfeld einsetzt. Im Laufe des Kurses wirst du verschiedene Testobjekte drucken, um die Eigenschaften nachvollziehen zu können. Am Ende des Kurses wirst du in der Lage sein, Druckprozesse anhand der Materialienauswahl und Druckparamtern zu optimieren und Probleme bei der Fertigung zu lösen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "Umgang mit FDM Drucker",
            "CAD Modellierung",
            "Fertigungsgerechtes Konstruieren",
            "Dateiformate verstehen",
            "Druck-Vorbereitung und -Durchführung",
            "Umgang mit der Maschine",
            "Eigenes Modell konstruieren und drucken",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "In diesem Kurs wirst du an realen Projekten arbeiten und maßgeschneiderte Teile und Prototypen fertigen, um dein Wissen und deine Fähigkeiten in der additiven Fertigung zu vertiefen. Am Ende des Kurses wirst du in der Lage sein, komplexe Designs zu erstellen und zu fertigen, Materialien zu optimieren und Probleme bei der Fertigung zu vermeiden.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Nextcloud",
    courseTitle: "Nextcloud",
    courseSubtitle: "Eine moderne und selbstverwaltete Cloud",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "cloud",
    mainTopics: ["Informatik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      { id: uuid4(), name: "Self-Hosted Cloud", color: "#3498DB" }
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Verwendung von Nextcloud",
            "Einrichtung einer persönlichen Cloud",
            "Verbindung und Synchronisation von (mobilen) Endgeräten",
            "Automatische Datensicherung",
            "Teilen von Daten",
            "Verwaltung eines eigenen Kalenders",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Nextcloud ist eine quelloffene Plattform zur Freigabe von Daten und der Zusammenarbeit. Gerade durch die offene Implementierung bietet Nextcloud interessante Möglichkeiten sowohl für private als auch industrielle Anwendungen. In diesem Kurs lernst du die Nutzung von Nextcloud über die Weboberfläche und die mobile App kennen. Nach der Einführung lernst Du, wie ein Nextcloud Konto erstellt wird und wie man sich anmeldet. Im Laufe des Kurses wirst Du lernen, wie man Dateien hochlädt und verwaltet, Dateien und Ordner mit anderen teilet, in Echtzeit an Dokumenten zusammenarbeitet und Daten geräteübergreifend synchronisiert. Sie werden auch lernen, wie Sie die in Nextcloud integrierten Kommunikationsfunktionen nutzen können, einschließlich der Chat- und Videokonferenz-Tools. Darüber hinaus behandelt der Kurs Themen wie Sicherheit, Datenschutz und Datensicherung, damit du mit den gängigen Prinzipien von Cloudspeichern vertraut bist. Am Ende des Kurses wirst Du ein solides Verständnis dafür haben, wie man Nextcloud für persönliche und berufliche Bedürfnisse nutzen kann. Egal, ob Du als Unternehmer die Zusammenarbeit im Team verbessern willst oder als Privatperson eine Alternative zu den gängigen Cloud Speicherlösungen suchst, dieser Kurs wird Dir das Wissen und die Fähigkeiten vermitteln, die Du für den Einstieg in Nextcloud benötigst.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Verwendung von Nextcloud in einer Projektumgebung",
			"Nextcloud als Projektplattform verstehen",
			"Möglichkeiten der Integration identifizieren",
			"Produktivitätssteigerung durch eine Plattform",
            "Kollaboration und Videokonferenzen",
          ],
          requirements:
            "Vorkenntnisse in der Bedienung von Nextcloud.",
          duration: "1 Tag",
          description:
            "Der Advance Kurs für Nextcloud ist für Personen konzipiert, die bereits Erfahrung in der Nutzung von Nextcloud haben und ihr Wissen auf die nächste Stufe bringen möchten. In diesem Kurs lernst Du, wie man die Funktionalität der Plattform anpasst, erweitert und andere Systeme integriert, um Nextcloud für mehrere Nutzer und Nutzergruppen zu verwalten. Der Kurs beginnt mit einem Überblick über fortgeschrittene Nextcloud-Konzepte, einschließlich Anpassung, Entwicklung und Skalierbarkeit. Du lernst, wie man die Benutzeroberfläche anpasst, neue Funktionen durch Apps und Plugins hinzufügt und Nextcloud mit externen Systemen über APIs und Webhooks integrieren kann. Im weiteren Verlauf lernst Du die verschiedenen Bereitstellungsmöglichkeiten von Nextcloud kennen, wie z.B. die Installation auf dem eigenen Server oder die Nutzung eines Cloud-Anbieters. Zusätzlich wird der Kurs Themen wie Sicherheit, Compliance und Datenverwaltung abdecken, um sicherzustellen, dass Du mit den bewährten Praktiken vertraut bist, die bei der Verwendung von Nextcloud in einer Mehrnutzerumgebung erforderlich sind. Am Ende des Kurses hast Du ein tieferes Verständnis für die Architektur und Fähigkeiten von Nextcloud und kannst das fortgeschrittene Wissen nutzen, um die Plattform an eigene Bedürfnisse anzupassen, zu erweitern und bereitzustellen. Egal, ob Du Entwickler, Systemadministrator oder IT-Enthusiast bist, dieser Kurs stattet dich mit dem Wissen und den Fähigkeiten aus, die Du benötigen, um ein Nextcloud produktiv nutzen und verwalten zu können.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "Nextcloud Server Setup und erweiterte Konfiguration",
			"Einen Linux Server aufsetzen",
            "Nextcloud auf einem Server installieren",
            "Backup Konzepte im Zusammenhang mit Nextcloud lernen",
            "Systemverwaltung auf Root Ebene"
          ],
          requirements:
            "Sicherer Umgang in der Bedienung von Nextcloud.",
          duration: "1 Tag",
          description:
            "Die letzte Stufe des Nextcloud Kurses wendet sich an Anwender, die zum Systemadminsitrator werden wollen. Im weiteren Verlauf des Kurses tauchst Du tiefer in das Backend von Nextcloud ein und lernst, wie der Server konfiguriert wird, die Leistung optimiert und Probleme behoben werden. Es werden fortgeschrittene Themen wie Hochverfügbarkeit, Lastausgleich und Datenreplikation untersucht. Dabei liegt das Augenmerk auf dem System \"Nextcloud\" in einer Serverumgebung. Es wird ein eigener Testserver aufgesetzt und konfiguriert. Dabei wird auf das Backend inklusive der Datenbankstruktur eingegangen. Ziel dieses Kurses ist es Nextcloud nicht nur zu bedienen, sondern in der Lage zu sein eine Nextcloud Instanz zu \"deployen\" und an eigene Anforderungen anzupassen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Sensorik",
    courseTitle: "Sensorik",
    courseSubtitle: "Messen und Erfassen",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "sensor",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Vortrag und Übung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "Embedded Systems", color: "#9B59B6" }
    ],
    tabs: [
      {
        key: "1",
        label: "Grundlagen",
        children: {
          learningOutcomes: [
            "Abstandssensorik verstehen",
            "Sensorarten",
            "Sensorwirkprinzip",
            "Induktive und kapatzitive Sensoren",
            "Optische Sensoren",
            "Akustische Sensoren",
            "Anwendung von Sensoren",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "Sensoren sind in vielen alltäglichen Bereichen unseres Lebens zu finden. In diesem Kurs werden Grundprinzipien von gängigen Sensoren erkundet, um die Wirkprinzipien besser nachvollziehen zu können. Es werden anhand von Beispielen und praktischen Übungen verschiedene Sensortypen untersucht und auf deren Eignung für spezifische Aufgabenbereiche bewertet.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Mikrocontroller",
    courseTitle: "Embedded Systems mit Mikrocontrollern",
    courseSubtitle: "Kleine Computer mit viel Potential",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "microcontroller",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Vortrag und Übung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "Embedded Systems", color: "#9B59B6" }
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Kennenlernen des Aufbaus eines Mikrocontrollers",
            "Programmieren in vereinfachtem C/C++",
            "Nutzen von digitalen Ein- und Ausgängen",
            "Nutzen von analogen Ein- und Ausgängen",
            "Entwurf und Programmierung einer Steuerung"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "In diesem Kurs werden die Grundkenntnisse der Mikrocontroller anhander der offenen Arduino Plattform vermittelt. Der Einstieg fällt hierbei besonders leicht, da keinerlei Vorkenntnisse notwendig sind um bereits nach kürzester Zeit Erfolge im Zusammenhang mit der Programmierung zu erkennen sind. Dieser Kurs dient dem ersten Konntakt mit der Materie der Mikrocontroller und beinhaltet kleine Übungen, die zum Verständis des Systems helfen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Kennenlernen des Aufbaus eines Mikrocontrollers",
            "Programmieren in klassischem C/C++",
            "Nutzen von digitalen Ein- und Ausgängen",
            "Nutzen von analogen Ein- und Ausgängen",
            "Entwurf und Programmierung einer Steuerung",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich, Vorkenntnisse in anderen Programmiersprachen sind hilfreich.",
          duration: "3 Tage",
          description:
            "Wer bereits die generelle Logik zum Erstellen von Programmen beherrscht und noch C/C++ im embedded Umfeld erlernen möchte, sollte diesen Kurs wählen. Anders als im Beginner Kurs wird hier klassisches C/C++ ohne Vereinfachungen programmiert. Dies bieten tiefere Einblicke in die Materie und ermöglicht ein besseres Verständnis für eingebettete System.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "Realisiserung eines Embedded Systems nach eigenen Vorstellungen",
            "Umsetzung eines selbst gewählten Programmierprojekts"
          ],
          requirements:
            "C/C++ Grundkenntnisse",
          duration: "3 Tage",
          description:
            "Wer gerne zusammen mit anderen programmiert und Projekte realisiert, ist hier genau richtig. Also ein Kurs für echte Macher und Macherinnen! Sie wählen ein eigenes Thema aus dem Bereich der eingebetteten Systemen wie z.B. IoT Sensoren für den Heimbereich, Elektromotorsteuerungen, etc. und erhalten professionelle Unterstützung bei der Realisierung des Projekts. Falls Sie keine Thema wissen, aber dennoch gerne Basteln, berate ich Sie gerne.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Python Grundkurs",
    courseTitle: "Python Grundkurs",
    courseSubtitle: "Programmieren lernen",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "python",
    mainTopics: ["Informatik"],
    courseType: "Vortrag und Übung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      { id: uuid4(), name: "Code Basics", color: "#2ECC71" }
    ],
    tabs: [
      {
        key: "1",
        label: "Grundlagen",
        children: {
          learningOutcomes: [
            "Grundlagen Python lernen",
            "Automatisierung von sich wiederholenden Aufgaben",
            "Steigerung der Produktivität in verschiedenen Bereichen",
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Python ist sehr vielseitig einsetzbar. So ist diese Hochsprache zum Einen gut geeignet um den Einstieg in die Programmierung zu wagen. Zum Anderen lassen sich mit ihr beispielsweise komplexe Aufgaben aus den Bereichen Statistik, Künstliche Intelligenz und Naturwissenschaften lösen. Generell lassen sich repetitive Aufgaben automatisieren um Zeit zu sparen und die Produktivität zu steigern. Egal ob Sie einen Start in die Welt des Programmierens suchen oder neue Herausforderungen programmtechnisch lösen wollen, dieser Kurs bietet für jeden Wissensstand interessante Anwendungen und Übungen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "SPS Programmierung",
    courseTitle: "SPS Programmierung",
    courseSubtitle: "Programmieren mit TIA und  S7",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "sps",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Hardware der SPS kennenlernen",
            "Hardwarekonfiguration",
            "Logikverknüpfungen",
            "Programmieren mit Logikbausteinen",
            "Automatisierung",
            "Programmieren mit dem Funktionsplan - FUP"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "7 Tage",
          description:
            "Der Beginner Kurs richtet sich an Teilnehmer, welche die Grundlagen der Logik und Steuerungen erlernen möchten. Dazu gehört die Einrichtung der Programmierumgebung sowie die Hardwarekonfiguration. Mit einfachen Programmierbeispielen wird langsam an das Thema herangerführt, was bis zur ersten kleinen Automatisierungslösung führt. Das Zusammenspiel zwischen Hard- und Software wird anschaulich erklärt und erleichtert so die ersten Schritte in der Welt der Automatisierung.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Human Machine Interface (HMI) kennenlernen",
            "Programmstruktur anwenden",
            "Fehler suchen und beheben",
            "Hilfswerkzeuge zur Optimierung kennenlernen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "7 Tage",
          description:
            "Dieser Kurs gibt iefere Einblicke in die Programmierung von speicherprogrammierbaren Steuerungen. Durch Übungen erlernt der Teilnehmer, wie man komplexe Steuerungslogik entwirft, fortgeschrittene Programmierstrukturen verwendet, Fehlerdiagnose und Debugging durchführt und die Netzwerkkommunikation optimiert.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
          	"Erarbeiten eines gesetzten Projektziels",
          	"Erweiterte Programmstrukturierung",
            "Structured Control Language - SCL",
          ],
          requirements:
            "Grundkenntnisse der SPS Logik und der FUP Programmiersprache",
          duration: "7 Tage",
          description:
            "Die Umsetzung eines Automatisierungsprojektes anhand von praxisorientierten Themen erfordert die Umsetzung bereits erlernter Zusammenhänge anhand von realen Problemstellungen. Zusätzlich besteht die Möglichkeit die \"Structured Control Language\" zu erlernen, um komplexe Berechnungen durchführen zu können.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
    {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Netzwerktechnik",
    courseTitle: "Netzwerktechnik",
    courseSubtitle: "Kommunikation über Ethernet",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "netzwerk",
    mainTopics: ["Informatik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      { id: uuid4(), name: "Netzwerkprotokolle", color: "#1ABC9C" }
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Aufbau von Netzwerken",
            "Der Ethernet Standard",
            "Kommunikation",
            "Adressierung",
            "Netzwerkkonfiguration",
            "IT-Sicherheit"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "In dieser Stufe werden Sie die Grundlagen der Netzwerktechnologie erlernen. Ziele sind das Wissen über den Aufbau von Netzwerken, wie Daten durch ein Netzwerk fließen und wie Netzwerke kommunizieren. Zusätzlich werden Sie die Grundlagen von Netzwerkprotokollen, IP-Adressierung, Subnetting und Netzwerksicherheit kennenlernen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Netzwerktopologien",
            "Switching",
            "Routing",
            "Implementierung von Netzwerksicherheit"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "In der nächsten Stufe werden Sie tiefer in die Netzwerktechnologie eintauchen. Sie werden lernen, wie Netzwerktopologien aufgebaut sind, wie Routing und Switching funktionieren, wie man ein Netzwerk designet und wie man Netzwerkleistung optimiert. Zusätzlich werden Sie sich mit fortgeschrittenen Netzwerkthemen wie Netzwerksicherheit und Netzwerküberwachung befassen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "Projektumsetzung mit realen Problemstellungen",
            "Entwurf und Planung von komplexen Netzwerken"
          ],
          requirements:
            "Grundlegende Kenntnisse der Netzwerktheorie",
          duration: "1 Tag",
          description:
            "In der letzten Stufe wird der Entwurf, der Aufbau und die Verwaltung von komplexe Netzwerke anhand von einem realistischen Projekt behandelt. Zusätzlich zur Problembehebung gibt es die Möglichkeit ein Netzwerk ganz nach eigenen Vorstellung aufzubauen und zu verwalten.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "PCB Design",
    courseTitle: "PCB Design",
    courseSubtitle: "Platinenentwurf mit KiCad",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "pcb",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "KiCad", color: "#34495E" }
    ],
    tabs: [
      {
        key: "1",
        label: "Beginner",
        children: {
          learningOutcomes: [
            "Umgang mit Open Source PCB Design Software",
            "Schaltpläne zeichnen und verstehen",
            "Platinenlayouts aus Schaltplänen ableiten"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs ist für Personen konzipiert, die neu in der Welt der Elektronik sind und noch keine Erfahrung mit PCB Design oder Elektronik haben. In diesem Kurs lernst Du den Umgang mit elektrischen Schaltungen, wie man Schaltpläne erstellt und schließlich ein Platine entwirft und layoutet.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Advanced",
        children: {
          learningOutcomes: [
            "Optimierung des Designs",
            "Bauteilbibliotheken",
            "Fertigung von Platinen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Der Advanced Kurs richtet sich an Personen, die bereits Erfahrung im mit Elektronik gesammelt haben und ihre Fähigkeiten um das PCD Design erweitern möchten. Die Optimierung und Fehlersuche von Platinen Layouts, sowie der erweiterte Umgang mit KiCad sind Bestandteil des Kurses. Dabei lernen die Teilnehmer, wie man benutzerdefinierte Bibliotheken erstellt und Designs für die Fertigung vorbereitest.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "3",
        label: "Pro",
        children: {
          learningOutcomes: [
            "fortgeschrittenes Routing",
            "Hochgeschwindigkeitsanwendungen",
            "fertigungsgerechtes Design",
            "Mehrschichtdesigns"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Die Profi Stufe richtet sich an Personen, die bereits Erfahrung im PCB Design haben und zu Experten in KiCad werden möchten. Lernziele sind fortgeschrittene Techniken für Routing und Layout, wie das Design für Hochgeschwindigkeitsanwendungen, fertigungsgerechtes Design und komplexe Mehrschichtdesigns.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },

  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Cybersecurity",
    courseTitle: "Cybersecurity",
    courseSubtitle: "Safety oder doch Security?",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "security",
    mainTopics: ["Informatik"],
    courseType: "Weiterbildung",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      { id: uuid4(), name: "Pentesting", color: "#C0392B" }
    ],
    tabs: [
      {
        key: "1",
        label: "Starter",
        children: {
          learningOutcomes: [
            "Umgang mit Technologie im Hinblick auf Sicherheit",
            "Best practice in der IT",
            "Was macht Sicherheit in einem digitalen Umfeld aus?"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs richtet sich an Einsteiger, die sich für die Themen IT-Sicherheit und Cyberschutz interessieren. Sie lernen, wie Sie Systeme vor Cyberbedrohungen schützen und Sicherheitslücken erkennen und schließen. Der Kurs bietet Ihnen einen umfassenden Einstieg in die Welt der Cybersecurity, von den Grundlagen bis hin zu spezifischen Schutzmaßnahmen und Notfallplänen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
      {
        key: "2",
        label: "Sicherheitsschulung",
        children: {
          learningOutcomes: [
            "Umgang mit Technologie im Hinblick auf Sicherheit",
            "Best practice in der IT",
            "Was macht Sicherheit in einem digitalen Umfeld aus?",
            "Firmenspezifische Sicherheitsmaßnahmen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs richtet sich an alle Berufsfelder innerhalb einer Firma und dient der Sensibilisierung für Cybersecurity im eigenen Unternehmen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "robotik",
    courseTitle: "Robotik",
    courseSubtitle: "Die Automatisierung der Zukunft",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "robot",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Weiterbildung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "Industrieroboter", color: "#8E44AD" }
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Verständnis für grundlegende Robotik Kinematik",
            "Programmierung und Interaktion mit Robotern",
            "Entwicklung und Simulation von Robotersystemen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "Dieser Kurs richtet sich an Personen, die sich für Robotik und Automatisierung interessieren. Sie werden lernen, wie Roboter aufgebaut sind, wie sie programmiert und gesteuert werden und wie Sie eigene Roboterlösungen entwickeln. Wir behandeln sowohl die technischen Grundlagen als auch die praktischen Anwendungen in verschiedenen Branchen. Der Kurs ist ideal für Personen, die eine Karriere in der Robotik anstreben oder ihr Wissen auf diesem zukunftsträchtigen Gebiet erweitern möchten.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "ihkabschluss",
    courseTitle: "Vorbereitung auf die IHK Abschlussprüfung",
    courseSubtitle: "Ihr Erfolgsweg zur Fachkraft",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "wiring",
    mainTopics: ["Informatik", "Elektrotechnik", "Maschinenbau"],
    courseType: "Ausbildung",
    badges: [

      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Sicherer Umgang mit den Prüfungsinhalten der IHK",
            "Erlernen von Techniken und Strategien für die Prüfung",
            "Festigung des Fachwissens durch praxisorientierte Übungen und Beispiele"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "Dieser Kurs richtet sich an Auszubildende, die sich auf die bevorstehende IHK-Abschlussprüfung vorbereiten möchten. Hier werden alle relevanten Themen der Prüfung behandelt, von technischen Grundlagen bis hin zu fachspezifischen Inhalten. Sie werden lernen, wie Sie Prüfungsaufgaben richtig angehen und Ihre Kenntnisse effektiv anwenden. Der Kurs bietet Ihnen gezielte Übungen und Unterstützung, um sicher und gut vorbereitet in die Abschlussprüfung zu gehen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "Installationstechnik",
    courseTitle: "Installationstechnik und Verdrahtung",
    courseSubtitle: "Die Grundlage für sichere und zuverlässige Systeme",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "installation",
    mainTopics: ["Elektrotechnik"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      { id: uuid4(), name: "Elektroinstallation", color: "#27AE60" }
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Planung und Umsetzung von elektrischen Installationen",
            "Anwendung der relevanten Normen und Vorschriften",
            "Wartung und Instandhaltung von Installationen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "Dieser Kurs richtet sich an Personen, die sich für die Installationstechnik und Verdrahtung von Anlagen und Elektroinstallationen interessieren. Sie lernen, wie Sie elektrische Systeme sicher installieren und warten. Dabei werden sowohl die Planung und Umsetzung von Installationen als auch das Arbeiten mit relevanten Normen und Vorschriften behandelt. Der Kurs ist perfekt für Einsteiger, die in der Elektrotechnik Fuß fassen möchten oder ihre Kenntnisse auf diesem Gebiet vertiefen oder aktualisieren wollen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "mechatronik",
    courseTitle: "Mechatronik Ausbildung",
    courseSubtitle: "Von der Theorie zur Praxis",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "mechatronik",
    mainTopics: ["Informatik", "Elektrotechnik", "Maschinenbau"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
      { id: uuid4(), name: "Automatisierungstechnik", color: "#2980B9" }
    ],
    tabs: [
      {
        key: "1",
        label: "Ausbildung",
        children: {
          learningOutcomes: [
            "Integration von Mechanik, Elektronik und Informatik",
            "Fehleranalyse und Reparatur von mechatronischen Systemen",
            "Entwicklung von Automatisierungsprozessen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "4 Wochen",
          description:
            "Dieser Kurs ist ideal für alle, die die Ausbildung zum Mechatroniker / zur Mechatronikerin anstreben oder ihre Kenntnisse auf diesem Gebiet erweitern möchten. Sie werden die Grundlagen der Mechatronik erlernen, Mechanik, Elektronik und Informatik miteinander verknüpfen und praxisorientierte Lösungen entwickeln. Der Kurs deckt alle wesentlichen Aspekte der Mechatronik ab, von der Wartung bis hin zur Optimierung von komplexen Systemen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "canbus",
    courseTitle: "CAN Bus Analyse & Hacking",
    courseSubtitle: "Verstehen und kontrollieren Sie Fahrzeugnetzwerke",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "can",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "Security", color: "#E67E22" }
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Grundlagen des CAN Bus und dessen Funktionsweise",
            "Analyse von Fahrzeugnetzwerken und Kommunikationsprotokollen",
            "Durchführung von Sicherheitsprüfungen und Reverse-engineering-Techniken auf CAN Bus-Systeme"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs richtet sich an Personen, die ein tiefes Verständnis für Fahrzeugnetzwerke und die Sicherheit des CAN Busses entwickeln möchten. Sie lernen, wie CAN Bus-Systeme in Fahrzeugen kommunizieren, wie Sie diese analysieren und Sicherheitslücken identifizieren. Der Kurs gibt Ihnen sowohl theoretisches Wissen als auch praktische Fähigkeiten, um den CAN Bus zu verstehen, zu testen und zu manipulieren.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "prodmanag",
    courseTitle: "Produktionsmanagement",
    courseSubtitle: "Optimierung von Prozessen für eine erfolgreiche Produktion",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "production",
    mainTopics: ["Informatik", "Elektrotechnik", "Maschinenbau"],
    courseType: "Weiterbildung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
      { id: uuid4(), name: "Lean Production", color: "#BDC3C7" }
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Entwicklung und Erprobung von Produktionsstrategien",
            "Anwendung von Lean-Management-Methoden zur Effizienzsteigerung",
            "Analyse und Optimierung von Produktionsabläufen und -ressourcen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "4 Wochen",
          description:
            "In diesem Kurs lernen Sie, wie Sie Produktionsprozesse effektiv planen, steuern und optimieren können. Sie erfahren, wie moderne Methoden des Produktionsmanagements, wie Lean-Management und die Just-in-Time-Produktion, eingesetzt werden, um Ressourcen zu schonen und Prozesse zu beschleunigen. Der Kurs bietet Ihnen praxisnahe Strategien und Techniken, um Produktionsabläufe zu analysieren, Fehlerquellen zu identifizieren und effiziente Lösungen umzusetzen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "anlagentechnik",
    courseTitle: "Anlagentechniker Ausbildung",
    courseSubtitle: "Ihre Schlüsselqualifikation für die Elektrotechnik in der Industrie",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "anlage",
    mainTopics: ["Informatik", "Elektrotechnik", "Maschinenbau"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Ausbildung",
        children: {
          learningOutcomes: [
            "Verständnis der elektrischen Infrastruktur von Maschinen und Anlagen",
            "Fehlerdiagnose und Instandhaltung von elektrischen Anlagen",
            "Planung und Umsetzung von elektrischen Installationen in der Industrie"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "4 Wochen",
          description:
            "Dieser Kurs richtet sich an Personen, die eine fundierte Ausbildung in der Elektrotechnik für industrielle Anlagen und Systeme anstreben. Sie lernen, wie elektrische Anlagen in der Produktion aufgebaut, gewartet und repariert werden. Der Kurs vermittelt sowohl theoretisches Wissen über elektrotechnische Grundlagen als auch praktische Fähigkeiten, um Fehler zu diagnostizieren und Reparaturen durchzuführen. Am Ende sind Sie in der Lage, elektrische Installationen zu planen, umzusetzen und sicherzustellen, dass diese reibungslos funktionieren.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "rapidprototyping",
    courseTitle: "Rapid Prototyping",
    courseSubtitle: "Ihre Idee in Rekordzeit umsetzen",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "prototype",
    mainTopics: ["Informatik", "Elektrotechnik", "Maschinenbau"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Einführung in die Technologien und Methoden des Rapid Prototyping",
            "Nutzung von 3D-Druck und anderen Prototyping-Techniken",
            "Entwicklung und Testen von ersten Prototypen für innovative Produkte"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "In diesem Kurs lernen Sie, wie Sie Ideen schnell in funktionale Prototypen umsetzen können, um Innovationen in kurzer Zeit zu testen. Sie erfahren, welche Technologien und Tools für Rapid Prototyping zur Verfügung stehen, wie z.B. der 3D-Druck sinnvoll angewendet werden kann und wie Prototypen in der Produktentwicklung genutzt werden. Der Kurs kombiniert Theorie mit praktischen Übungen, um Ihnen die nötigen Fähigkeiten zu vermitteln, Prototypen effizient zu erstellen und zu testen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "halbleiter",
    courseTitle: "Halbleitertechnik",
    courseSubtitle: "Der Grundbaustein moderner Technologien",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "semiconductor",
    mainTopics: ["Elektrotechnik"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Ausbildung",
        children: {
          learningOutcomes: [
            "Verständnis der Halbleiterphysik und ihrer Eigenschaften",
            "Einsatzmöglichkeiten von Halbleitern in verschiedenen Bereichen",
            "Eigenschaften von Halbleiterbauelementen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "In diesem Kurs lernen Sie die grundlegenden Prinzipien der Halbleitertechnik und deren Anwendung in modernen elektronischen Geräten. Sie erfahren, wie Halbleiterbauelemente wie Dioden, Transistoren und Mikrochips hergestellt und eingesetzt werden. Der Kurs kombiniert Theorie mit praktischen Anwendungen, um Ihnen ein tiefes Verständnis für die Funktionsweise und die Entwicklung von Halbleitertechnologien zu vermitteln.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "regelungstechnik",
    courseTitle: "Regelungstechnik",
    courseSubtitle: "Die Kunst der präzisen Prozesssteuerung",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "regelung",
    mainTopics: ["Elektrotechnik"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "PID-Regler", color: "#5D6D7E" }
    ],
    tabs: [
      {
        key: "1",
        label: "Ausbildung",
        children: {
          learningOutcomes: [
            "Grundlagen und Prinzipien der Regelungstechnik",
            "Anwendung von Regelsystemen in verschiedenen Industrien",
            "Modellierung und Simulation von Regelkreisen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "Dieser Kurs richtet sich an Personen, die das Konzept der Regelungstechnik und ihre Anwendung auf industrielle Prozesse verstehen möchten. Sie lernen die Prinzipien der Regelkreise und deren Einfluss auf die Optimierung von Maschinen und Systemen kennen. Der Kurs vermittelt sowohl theoretische Kenntnisse zur Analyse von Regelungssystemen als auch praktische Ansätze zur Implementierung von Regelsystemen in der industriellen Praxis.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "pneumatik",
    courseTitle: "Pneumatik und Elektropneumatik",
    courseSubtitle: "Nicht nur heiße Luft",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "pneumatik",
    mainTopics: ["Elektrotechnik", "Maschinenbau"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
      { id: uuid4(), name: "Druckluftsysteme", color: "#A569BD" },
      { id: uuid4(), name: "Ventilsteuerung", color: "#EC7063" }
    ],
    tabs: [
      {
        key: "1",
        label: "Ausbildung",
        children: {
          learningOutcomes: [
            "Funktionsweise von pneumatischen Systemen und deren Anwendung",
            "Integration von Elektropneumatik in industrielle Steuerungssysteme",
            "Wartung und Fehlerdiagnose in pneumatischen Anlagen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "In diesem Kurs lernen Sie, wie pneumatische und elektropneumatische Systeme in industriellen Anwendungen eingesetzt werden. Sie erfahren, wie Luftdrucksysteme und elektrische Steuerungen für die Automatisierung von Prozessen verwendet werden. Der Kurs kombiniert Theorie mit praktischen Übungen, um Ihnen die nötigen Fähigkeiten zu vermitteln, um pneumatische Anlagen zu verstehen, zu warten und zu optimieren.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "leistungselektronik",
    courseTitle: "Leistungselektronik",
    courseSubtitle: "Leistung ist Arbeit pro Zeit",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "elektronik",
    mainTopics: ["Elektrotechnik"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "Leistungshalbleiter", color: "#5499C7" }
    ],
    tabs: [
      {
        key: "1",
        label: "Ausbildung",
        children: {
          learningOutcomes: [
            "Grundlagen und Anwendungen der Leistungselektronik",
            "Umwandlung von elektrischer Energie",
            "Analyse und Design von Leistungselektronikschaltungen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "Dieser Kurs richtet sich an Personen, die die Grundprinzipien der Leistungselektronik verstehen und anwenden möchten. Sie lernen, wie elektrische Energie effizient umgewandelt, gesteuert und verteilt wird und welche Bauteile wie Transistoren, Dioden und Thyristoren dabei eine Rolle spielen. Der Kurs vermittelt Ihnen sowohl theoretische Kenntnisse als auch praktische Fähigkeiten, um Schaltungen der Leistungselektronik zu entwickeln und zu optimieren.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "cad",
    courseTitle: "CAD",
    courseSubtitle: "Von der Konstruktion bis zur Fertigung",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "cad",
    mainTopics: ["Maschinenbau"],
    courseType: "Weiterbildung",
    badges: [
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
      { id: uuid4(), name: "Konstruktion", color: "#E74C3C" }
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Grundlagen der CAD-Software und selbstständiges Erstellen von 2D- und 3D-Modellen",
            "Kennenlernen von Konstruktionsprinzipien und technischen Zeichnungen",
            "Fertigungsgerechtes Konstruieren für Ihren Anwendungsfall"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "Dieser CAD-Kurs richtet sich an Personen, die die Grundlagen des Computer-Aided Design (CAD) erlernen und anwenden möchten. Sie erfahren, wie digitale Modelle und technische Zeichnungen erstellt, bearbeitet und optimiert werden, um komplexe Designs in verschiedenen Bereichen wie Maschinenbau, Architektur oder Produktentwicklung umzusetzen. Der Kurs vermittelt Ihnen sowohl die theoretischen Kenntnisse als auch praktische Fähigkeiten, um CAD-Software effektiv zu nutzen und Designs präzise und effizient zu erstellen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "kuenstlicheintelligenz",
    courseTitle: "Künstliche Intelligenz",
    courseSubtitle: "Entfesseln Sie das Potenzial von KI, AI und Machine Learning",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "ki",
    mainTopics: ["Informatik"],
    courseType: "Bootcamp",
    badges: [
      {
        id: uuid4(),
        name: "Bootcamp",
        color: "#9A031E",
      },
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      { id: uuid4(), name: "Neuronale Netze", color: "#58D68D" }
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Grundlagen und Anwendung von Künstlicher Intelligenz",
            "Verständnis einfacher KI-Modelle und Algorithmen",
            "Dikussion der ethischen und gesellschaftlichen Auswirkungen von KI"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs richtet sich an alle, die ein Verständnis für neue Technologien wie Künstliche Intelligenz (KI), Maschinelles Lernen (ML) und ihre Anwendung in der Praxis entwickeln möchten. Sie erfahren, wie KI-Algorithmen arbeiten, wie Sie maschinelles Lernen nutzen können und welche ethischen sowie gesellschaftlichen Herausforderungen mit diesen Technologien verbunden sind.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "antriebe",
    courseTitle: "Elektrische Antriebe",
    courseSubtitle: "Was die Welt bewegt",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "motor",
    mainTopics: ["Elektrotechnik"],
    courseType: "Ausbildung",
    badges: [
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "Asynchronmaschine", color: "#2E4053" },
      { id: uuid4(), name: "Synchronmaschine", color: "#2E4030" },
      { id: uuid4(), name: "DC Antriebe", color: "#2E6053" }
    ],
    tabs: [
      {
        key: "1",
        label: "Ausbildung",
        children: {
          learningOutcomes: [
            "Funktionsweise und Typen elektrischer Maschinen",
            "Nutzungsgebiete von elektrischen Maschinen",
            "Ansteuerung elektrischer Maschinen"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "5 Tage",
          description:
            "Dieser Kurs richtet sich an alle, die ein vertieftes Verständnis für elektrische Maschinen und deren Anwendungen in der Industrie entwickeln möchten. Sie lernen die Funktionsweise von Motoren, Generatoren und Transformatoren kennen, wie sie im industriellen Umfeld eingesetzt werden und wie Sie diese effektiv betreiben können. Der Kurs vermittelt sowohl theoretische als auch praktische Kenntnisse, um elektrische Maschinen erfolgreich zu identifizieren und zu betreiben.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "iot",
    courseTitle: "IoT (Internet of Things)",
    courseSubtitle: "Wie Vernetzung die Zukunft verändert",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "iot",
    mainTopics: ["Informatik", "Elektrotechnik"],
    courseType: "Weiterbildung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      { id: uuid4(), name: "Smart Devices", color: "#273746" }
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Grundlagen der IoT-Architektur",
            "Entwicklung von IoT-Anwendungen und -Geräten",
            "Sicherheitsaspekte und Datenmanagement im IoT-Umfeld"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs richtet sich an alle, die ein Verständnis für das Internet der Dinge (IoT) entwickeln möchten. Sie lernen, wie Geräte miteinander vernetzt werden, um Daten auszutauschen und intelligente Systeme zu schaffen. Der Kurs vermittelt Ihnen theoretische Grundlagen zur IoT-Architektur und ermöglicht Ihnen, praktische IoT-Anwendungen zu entwickeln. Sie erfahren zudem, wie Sie Sicherheits- und Datenschutzaspekte im Zusammenhang mit IoT-Projekten beachten können.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "linuxscript",
    courseTitle: "Linux Skripting",
    courseSubtitle: "Automatisieren von Arbeitsprozessen = Effizienzsteigerung",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "linux",
    mainTopics: ["Informatik"],
    courseType: "Weiterbildung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      { id: uuid4(), name: "Shell Scripts", color: "#F39C12" }
    ],
    tabs: [
      {
        key: "1",
        label: "Getting started",
        children: {
          learningOutcomes: [
            "Erstellung und Bearbeitung von Bash-Skripten für die Automatisierung",
            "Nutzung von Linux-Kommandos zur Systemadministration",
            "Fehlerbehebung und Skriptoptimierung in der Praxis"
          ],
          requirements:
            "Keine Vorkenntnisse erforderlich.",
          duration: "1 Tag",
          description:
            "Dieser Kurs richtet sich an Personen, die ihre Kenntnisse im Umgang mit Linux erweitern und Automatisierungsprozesse mit Skripten erstellen möchten. Sie lernen, wie Sie einfache und komplexe Bash-Skripte schreiben, um wiederkehrende Aufgaben zu automatisieren, Daten zu verarbeiten und das System zu verwalten. Der Kurs vermittelt praxisorientiertes Wissen zur Skripterstellung, Fehlerbehebung und Optimierung von Prozessen in Linux-Umgebungen.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
{
  // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "datenbanken",
  courseTitle: "Datenbanken",
  courseSubtitle: "Effiziente Datenorganisation",
  // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
  imgName: "database",
  mainTopics: ["Informatik"],
  courseType: "Weiterbildung",
  badges: [
    {
      id: uuid4(),
      name: "Informatik",
      color: "#993888",
    },
    { id: uuid4(), name: "SQL", color: "#1ABC9C" }
  ],
  tabs: [
    {
      key: "1",
      label: "Getting started",
      children: {
        learningOutcomes: [
          "Einführung in relationale Datenbanken",
          "Erstellung und Verwaltung von Datenbankstrukturen",
          "Anwendung von SQL zur Abfrage und Manipulation von Daten"
        ],
        requirements:
          "Keine Vorkenntnisse erforderlich.",
        duration: "1 Tag",
        description:
          "Dieser Kurs richtet sich an Personen, die ein tiefgehendes Verständnis für den Umgang mit Datenbanken entwickeln möchten. Sie lernen, wie relationale Datenbanken aufgebaut sind, wie Sie mit SQL Daten abfragen und verändern und wie Sie Datenbankstrukturen effizient entwerfen. Der Kurs bietet sowohl theoretische Einblicke in Datenbankdesign und -management als auch praktische Übungen zur Erstellung und Verwaltung von Datenbanken.",
        price: "\"Preis auf Anfrage\"",
      },
    },
  ],
},
{
  // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "server",
  courseTitle: "Servertechnologien",
  courseSubtitle: "Das Linuxadmin 101",
  // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
  imgName: "server",
  mainTopics: ["Informatik"],
  courseType: "Weiterbildung",
  badges: [
    {
      id: uuid4(),
      name: "Informatik",
      color: "#993888",
    },
    { id: uuid4(), name: "Virtualisierung", color: "#34495E" },
    { id: uuid4(), name: "Container", color: "#60495E" }
  ],
  tabs: [
    {
      key: "1",
      label: "Getting started",
      children: {
        learningOutcomes: [
          "Verständnis der Architektur und Funktionsweise von Servern",
          "Konfiguration und Verwaltung von Servern in Netzwerken",
          "Sicherheitsaspekte und Serverwartung in der Praxis"
        ],
        requirements:
          "Keine Vorkenntnisse erforderlich.",
        duration: "1 Tag",
        description:
          "Dieser Kurs richtet sich an Personen, die ihre Kenntnisse in Servertechnologien vertiefen möchten, um IT-Infrastrukturen erfolgreich zu gestalten und zu betreiben. Sie lernen, wie Server in Netzwerken konzipiert, implementiert und gewartet werden, welche Sicherheitsaspekte zu beachten sind und wie Sie Serverumgebungen effizient verwalten können. Der Kurs kombiniert theoretische Grundlagen mit praktischen Anwendungen, um Ihnen die Fähigkeiten zu vermitteln, Serverlösungen in realen Szenarien zu optimieren.",
        price: "\"Preis auf Anfrage\"",
      },
    },
  ],
},
{
  // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "digitalisierung",
  courseTitle: "Digitalisierung",
  courseSubtitle: "Digitale Transformation verstehen und anwenden",
  imgName: "car",
  mainTopics: ["Informatik", "Technologie", "Prozesse"],
  courseType: "Workshop",
  badges: [
    { id: uuid4(), name: "Workshop", color: "#1E88E5" },
    { id: uuid4(), name: "Informatik", color: "#993888" },
    { id: uuid4(), name: "Technologie", color: "#FB5607" }
  ],
  tabs: [
    {
      key: "1",
      label: "Einführung in die Digitalisierung",
      children: {
        learningOutcomes: [
          "Grundlagen der digitalen Transformation verstehen",
          "Technologien der Digitalisierung kennenlernen",
          "Anwendungsbeispiele aus der Industrie und Wirtschaft"
        ],
        requirements: "Keine Vorkenntnisse erforderlich.",
        duration: "2 Tage",
        description: "Dieser Workshop vermittelt Ihnen die Grundlagen der Digitalisierung und deren Einfluss auf Unternehmen und Geschäftsprozesse. Sie lernen digitale Technologien und deren Anwendungsmöglichkeiten kennen und erfahren, wie digitale Transformation erfolgreich umgesetzt werden kann.",
        price: "Preis auf Anfrage"
      }
    }
  ]
},
{
  // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "studiumsvorbereitung",
  courseTitle: "Vorbereitung aufs Studium",
  courseSubtitle: "Mathematik und Elektrotechnik Grundlagen meistern",
  imgName: "studium",
  mainTopics: ["Mathematik", "Elektrotechnik", "Studiumsvorbereitung"],
  courseType: "Intensivkurs",
  badges: [
    { id: uuid4(), name: "Intensivkurs", color: "#E63946" },
    { id: uuid4(), name: "Mathematik", color: "#457B9D" },
    { id: uuid4(), name: "Elektrotechnik", color: "#1D3557" }
  ],
  tabs: [
    {
      key: "1",
      label: "Grundlagen für einen erfolgreichen Start",
      children: {
        learningOutcomes: [
          "Mathematische Grundlagen für Ingenieurstudiengänge",
          "Grundlagen der Elektrotechnik verstehen",
          "Vorbereitung auf die ersten Semester im Studium"
        ],
        requirements: "Grundkenntnisse in Mathematik hilfreich, aber nicht erforderlich.",
        duration: "4 Wochen",
        description: "Dieser Intensivkurs richtet sich an Studienanfänger und vermittelt die notwendigen Grundlagen in Mathematik und Elektrotechnik. Durch praxisnahe Beispiele und Übungen bereiten wir Sie optimal auf das Studium vor.",
        price: "Preis auf Anfrage"
      }
    }
  ]
},
{
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "projektbetreuung",
  courseTitle: "Projekt- und Praktikumsbetreuung",
  courseSubtitle: "Erfolgreiches Arbeiten an Projekten und Praktika",
  imgName: "coworking",
  mainTopics: ["Projektmanagement", "Praxis", "Beratung"],
  courseType: "Coaching",
  badges: [
    { id: uuid4(), name: "Coaching", color: "#F4A261" },
    { id: uuid4(), name: "Projektmanagement", color: "#2A9D8F" },
    { id: uuid4(), name: "Praxis", color: "#264653" }
  ],
  tabs: [
    {
      key: "1",
      label: "Individuelle Unterstützung für Projekte",
      children: {
        learningOutcomes: [
          "Effiziente Planung und Umsetzung von Projekten",
          "Praxisorientierte Beratung für Praktika",
          "Lösungsstrategien für technische Herausforderungen"
        ],
        requirements: "Eigenes Projekt oder Praktikum in Vorbereitung.",
        duration: "Nach Vereinbarung",
        description: "Unser Coaching-Programm unterstützt Sie bei der erfolgreichen Durchführung von Projekten und Praktika. Wir bieten individuelle Betreuung, um Herausforderungen zu meistern und optimale Ergebnisse zu erzielen.",
        price: "Preis auf Anfrage"
      }
    }
  ]
},
{
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "din-vde-0100-600",
  courseTitle: "DIN VDE 0100-600 Prüfung",
  courseSubtitle: "Prüfung elektrischer Anlagen nach Norm",
  imgName: "measure",
  mainTopics: ["Elektrotechnik", "Normen", "Sicherheit"],
  courseType: "Ausbildung",
  badges: [
    { id: uuid4(), name: "Sicherheit", color: "#D72638" },
    { id: uuid4(), name: "Elektrotechnik", color: "#3A86FF" },
    { id: uuid4(), name: "Normen", color: "#8338EC" }
  ],
  tabs: [
    {
      key: "1",
      label: "Prüfung elektrischer Anlagen",
      children: {
        learningOutcomes: [
          "Normgerechte Prüfung nach DIN VDE 0100-600",
          "Messverfahren und Dokumentation",
          "Sicherheitsaspekte und Fehlervermeidung"
        ],
        requirements: "Grundkenntnisse in Elektrotechnik erforderlich.",
        duration: "5 Tage",
        description: "In dieser Schulung lernen Sie die normgerechte Prüfung elektrischer Anlagen nach DIN VDE 0100-600 kennen. Sie erhalten praktische Einblicke in Messverfahren und erfahren, wie Sie Prüfprotokolle korrekt erstellen.",
        price: "Preis auf Anfrage"
      }
    }
  ]
},
{
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "digitaltechnik",
  courseTitle: "Digitaltechnik",
  courseSubtitle: "Grundlagen der digitalen Schaltungstechnik",
  imgName: "digital",
  mainTopics: ["Elektrotechnik", "Logik", "Schaltungstechnik"],
  courseType: "Ausbildung",
  badges: [
    { id: uuid4(), name: "Schaltungen", color: "#FF6F61" },
    { id: uuid4(), name: "Elektrotechnik", color: "#3A86FF" },
    { id: uuid4(), name: "Logik", color: "#8338EC" }
  ],
  tabs: [
    {
      key: "1",
      label: "Einführung in die Digitaltechnik",
      children: {
        learningOutcomes: [
          "Grundlagen digitaler Schaltungen verstehen",
          "Binäre Logik und ihre Anwendungen",
          "Schaltungsdesign und Implementierung"
        ],
        requirements: "Grundlagen der Elektrotechnik hilfreich.",
        duration: "5 Tage",
        description: "Dieses Seminar bietet eine Einführung in die Digitaltechnik und deren Anwendungen. Sie lernen grundlegende Schaltungen und logische Verknüpfungen kennen. Mit der Hilfe von Beispielen und Übungen wird das theoretische Wissen wiederholt und gefestigt.",
        price: "Preis auf Anfrage"
      }
    }
  ]
},
{
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "gebaeude-infrastruktur",
  courseTitle: "Gebäude und Infrastruktur",
  courseSubtitle: "Planung und Technik in modernen Gebäuden",
  imgName: "building",
  mainTopics: ["Elektrotechnik"],
  courseType: "Workshop",
  badges: [
    { id: uuid4(), name: "Workshop", color: "#264653" },
    { id: uuid4(), name: "Elektrotechnik", color: "#E63946" }
  ],
  tabs: [
    {
      key: "1",
      label: "Grundlagen der Gebäudetechnik",
      children: {
        learningOutcomes: [
          "Verständnis für moderne Infrastruktur und Gebäudetechnik",
          "Energieeffizienz und nachhaltige Lösungen",
          "Integration smarter Technologien in Gebäuden"
        ],
        requirements: "Grundlagen in Elektrotechnik hilfreich.",
        duration: "5 Tage",
        description: "Dieser Workshop vermittelt praxisnahes Wissen über moderne Gebäudetechnik und Infrastruktur. Sie lernen die wichtigsten Technologien für nachhaltige und smarte Gebäude kennen und erfahren, wie eine effiziente Planung erfolgt.",
        price: "Preis auf Anfrage"
      }
    }
  ]
},
{
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "ausbildung-industrieelektriker",
  courseTitle: "Industrieelektriker Ausbildung",
  courseSubtitle: "Alles über Elektrotechnik in der Industrie",
  imgName: "elektrik",
  mainTopics: ["Elektrotechnik", "Industrie", "Ausbildung"],
  courseType: "Ausbildung",
  badges: [
    { id: uuid4(), name: "Elektrotechnik", color: "#3A86FF" },
    { id: uuid4(), name: "Industrie", color: "#8338EC" }
  ],
  tabs: [
    {
      key: "1",
      label: "Grundlagen der Industrieelektrik",
      children: {
        learningOutcomes: [
          "Verständnis industrieller Steuerungssysteme",
          "Fehlersuche und Wartung in Produktionsanlagen",
          "Einhaltung von Sicherheitsnormen in der Elektrotechnik"
        ],
        requirements: "Technisches Verständnis von Vorteil.",
        duration: "4 Wochen",
        description: "Dieser Lehrgang bereitet angehende Industrieelektriker und Industrieelektrikerinnen auf ihre berufliche Tätigkeit vor. Es werden theoretische Grundlagen vermittelt und praktische Fertigkeiten in der Wartung und Installation industrieller Systeme erlernt.",
        price: "Preis auf Anfrage"
      }
    }
  ]
},
{
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
  id: "abschlusspruefung-ap1-ap2",
  courseTitle: "Vorbereitung auf die Abschlussprüfung Teil 1 und 2",
  courseSubtitle: "Gezielte Vorbereitung auf die Abschlussprüfungen",
  imgName: "books",
  mainTopics: ["Elektrotechnik", "Prüfungsvorbereitung", "Ausbildung"],
  courseType: "Intensivkurs",
  badges: [
    { id: uuid4(), name: "Intensivkurs", color: "#D72638" },
    { id: uuid4(), name: "Elektrotechnik", color: "#3A86FF" },
    { id: uuid4(), name: "Prüfungsvorbereitung", color: "#8338EC" }
  ],
  tabs: [
    {
      key: "1",
      label: "Vorbereitung auf AP1",
      children: {
        learningOutcomes: [
          "Verständnis der Prüfungsinhalte und -anforderungen",
          "Gezielte Übung von Prüfungsaufgaben",
          "Praktische und theoretische Vorbereitung auf die Prüfung"
        ],
        requirements: "Grundlagen der Elektrotechnik und abgeschlossene Ausbildungsabschnitte erforderlich.",
        duration: "4 Wochen",
        description: "Dieser Intensivkurs bereitet Auszubildende optimal auf die Abschlussprüfungen Teil 1 vor. Anhand von Prüfungsfragen und praktischen Übungen werden die wichtigsten Inhalte wiederholt und vertieft.",
        price: "Preis auf Anfrage"
      }
    },
    {
      key: "2",
      label: "Vorbereitung auf AP2",
      children: {
        learningOutcomes: [
          "Verständnis der Prüfungsinhalte und -anforderungen",
          "Gezielte Übung von Prüfungsaufgaben",
          "Praktische und theoretische Vorbereitung auf die Prüfung"
        ],
        requirements:
          "Das erfolgreiche Absolvieren aller Ausbildungsabschnitte sowie eine abgeschlossene AP1 Prüfung sind erforderlich.",
        duration: "4 Wochen",
        description:
          "Nach bestandener AP1 geht es zügig auf den zweiten Teil der Abschlussprüfung zu. Dabei können wir auch unterstützen und anhand von praktischen Übungen bereits Erlerntes wiederholen und auf die neuen Herausforderungen der AP2 vorbereiten.",
        price: "\"Preis auf Anfrage\"",
      }
    }
  ],
},
{
  id: "webdesign",
  courseTitle: "Webdesign",
  courseSubtitle: "Von der ersten Webseite bis zur interaktiven Anwendung",
  imgName: "webdesign",
  mainTopics: ["Webentwicklung", "HTML", "CSS", "JavaScript"],
  courseType: "Workshop",
  badges: [
    { id: uuid4(), name: "Workshop", color: "#264653" },
    { id: uuid4(), name: "Webentwicklung", color: "#2A9D8F"},
    { id: uuid4(), name: "Informatik", color: "#993888" },
    { id: uuid4(), name: "HTML & CSS", color: "#E63946" },
    { id: uuid4(), name: "JavaScript", color: "#F4A261" }
  ],
  tabs: [
    {
      key: "1",
      label: "Grundlagen mit HTML & CSS",
      children: {
        learningOutcomes: [
          "Grundlagen von HTML und CSS verstehen",
          "Strukturierte und ansprechende Webseiten gestalten",
          "Responsives Design für verschiedene Geräte umsetzen"
        ],
        requirements: "Keine Vorkenntnisse erforderlich.",
        duration: "5 Tage",
        description: "In diesem Kurs lernen Sie die Grundlagen der Webentwicklung mit HTML und CSS. Sie erstellen erste Webseiten und gestalten diese mit modernen Layout-Techniken. Der Fokus liegt auf sauberem Code, responsivem Design und Benutzerfreundlichkeit.",
        price: "Preis auf Anfrage"
      }
    },
    {
      key: "2",
      label: "Interaktive Webseiten mit JavaScript",
      children: {
        learningOutcomes: [
          "Grundlagen von JavaScript verstehen",
          "Interaktive Elemente und Benutzerinteraktionen umsetzen",
          "Dynamische Webseiten mit DOM-Manipulation entwickeln"
        ],
        requirements: "Grundlagen in HTML & CSS erforderlich.",
        duration: "5 Tage",
        description: "In der zweiten Stufe des Webdesign-Kurses vertiefen Sie Ihre Kenntnisse mit JavaScript. Sie lernen, wie Sie Webseiten interaktiv gestalten, Benutzerinteraktionen verarbeiten und dynamische Inhalte erstellen.",
        price: "Preis auf Anfrage"
      }
    },
  ]
},
{
  id: "python-datenanalyse",
  courseTitle: "Python für Datenanalyse",
  courseSubtitle: "Daten effizient auswerten und visualisieren",
  imgName: "data",
  mainTopics: ["Datenanalyse", "Python", "Maschinelles Lernen"],
  courseType: "Workshop",
  badges: [
    { id: uuid4(), name: "Workshop", color: "#264653" },
    { id: uuid4(), name: "Informatik", color: "#993888" },
    { id: uuid4(), name: "Datenanalyse", color: "#2A9D8F" },
    { id: uuid4(), name: "Python", color: "#E63946" }
  ],
  tabs: [
    {
      key: "1",
      label: "Grundlagen der Datenanalyse mit Python",
      children: {
        learningOutcomes: [
          "Daten mit Python effizient verarbeiten",
          "Daten visualisieren",
          "Datenspeicherung und Analyse"
        ],
        requirements: "Grundkenntnisse in Python empfohlen.",
        duration: "1 Tage",
        description: "In diesem Workshop lernen Sie, wie Sie mit Python Daten analysieren und visualisieren können. Wir behandeln gängige Bibliotheken, um strukturierte Daten effizient zu verarbeiten und darzustellen.",
        price: "Preis auf Anfrage"
      }
    },
    {
      key: "2",
      label: "Erweiterte Datenanalyse und maschinelles Lernen",
      children: {
        learningOutcomes: [
          "Datenaufbereitung für Machine Learning verstehen",
          "Kennenkernen einfacher ML-Modelle",
          "Feature Engineering und Modellbewertung durchführen"
        ],
        requirements: "Grundlagen der Datenanalyse mit Python erforderlich.",
        duration: "5 Tage",
        description: "In der zweiten Stufe des Kurses lernen Sie fortgeschrittene Techniken der Datenanalyse und eine Einführung ins maschinelle Lernen mit Python. Sie erfahren, wie Sie Modelle trainieren und auswerten können, um datengetriebene Entscheidungen zu treffen.",
        price: "Preis auf Anfrage"
      }
    },
  ],
},







// ###################################################################################################################
  {
    // id wird als Wert in das Kontaktformular gespeist - muss unique sein --> also einfach den Kursnamen verwenden
    id: "placeholderCourse",
    courseTitle: "Kurs nicht gefunden?",
    courseSubtitle: "Stellen Sie gerne eine Anfrage für ein individuelles Angebot!",
    // Bild mit diesem Namen und der Endung `.webp` im Ordner `img/homepage/courses` speichern
    imgName: "anfrage",
    mainTopics: ["Informatik", "Elektrotechnik", "Maschinenbau"],
    courseType: "Weiterbildung",
    badges: [
      {
        id: uuid4(),
        name: "Informatik",
        color: "#993888",
      },
      {
        id: uuid4(),
        name: "Elektrotechnik",
        color: "#FB5607",
      },
      {
        id: uuid4(),
        name: "Maschinenbau",
        color: "#8338EC",
      },
    ],
    tabs: [
      {
        key: "1",
        label: "Beschreibung",
        children: {
          learningOutcomes: [
            "Wollen Sie neue Fähigkeiten erwerben?",
            "Gibt es ein Thema oder Projekt, das Sie schon lange interessiert?",
            "Wollen Sie bekanntes Wissen wieder auffrischen?",
          ],
          requirements:
            "Kursabhängig",
          duration: "Nach Abmachung.",
          description:
            "Konnten Sie den gesuchten Kurs nicht in dem Kursprogramm finden? Dann Fragen Sie gerne nach. Auch individuelle Kurse mit selbst gesetzten Zielen und Anforderungen sind möglich.",
          price: "\"Preis auf Anfrage\"",
        },
      },
    ],
  },
  
  
];

export default courseEntries;
